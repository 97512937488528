/*
* Preloader
* */
$(window).on('load', function() {
    setTimeout(function () {
        $('.preloader-wrapper').fadeOut('slow');
        $('body').removeClass('preloader-hidden');
        AOS.init({delay: 250, once: true, duration: 800});
    }, 1000);
});

// progress bar
var width = 100,
    perfData = window.performance.timing, // The PerformanceTiming interface
    EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart), // Calculated Estimated Time of Page Load which returns negative value.
    time = parseInt((EstimatedTime/1000)%60)*40; //Converting EstimatedTime from miliseconds to seconds.

$('progress').animate({
    value: width
}, time);
