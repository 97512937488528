require('./bootstrap');
require('./preloader');

$(function() {
    let cookieKiller = $('#cookieKiller');
    let boxGDPR = $('#moduleGDPR');
    let btnGDPR = $('#moduleGDPR #buttonGDPR');

    function setAgreeCookie() {
        let expire = new Date();
        expire = new Date(expire.getTime() + (3600 * 1000) * 8750);
        document.cookie = "boxGDPR=true; expires=" + expire;
    }

    if (document.cookie.indexOf("boxGDPR=") >= 0) {
        $(boxGDPR).addClass('hidden');
    }
    else {
        $(boxGDPR).removeClass('hidden');
    }

    $(btnGDPR).on('click', function () {
        setAgreeCookie();
        $(boxGDPR).addClass('hidden');
    });

    $(cookieKiller).on('click', function () {
        document.cookie = "boxGDPR=true; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        $(boxGDPR).removeClass('hidden');
    });

});


/*
* Menu
* */
if ($('#main-nav').length > 0) { // check if element exists
    var last_scroll_top = 0;
    $(window).on('scroll load', function() {
        scroll_top = $(this).scrollTop();

        if(scroll_top <= last_scroll_top) {
            $('#main-nav').removeClass('scrolled-down').addClass('scrolled-up');
        }
        else {
            $('#main-nav').removeClass('scrolled-up').addClass('scrolled-down');
        }

        if (scroll_top > 0) {
            $('#main-nav').addClass('scrolled-shadow');

            // If homepage
            $('#main-nav').removeClass('homepage-remove-white-background');
        } else {
            $('#main-nav').removeClass('scrolled-shadow');

            // If homepage
            $('#main-nav').addClass('homepage-remove-white-background');
        }
        last_scroll_top = scroll_top;
    });
}

// Hamburger
$(function () {
    $('.hamburger').click(function () {
        $('.hamburger').toggleClass('is-active');
        $('.menu-nav').toggleClass('show-hamburger');
        $('html').toggleClass('overflow-hidden');

        if ($(window).width() > 1201) {
            $('.logo-white').toggleClass('d-none');
            $('.logo-dark').toggleClass('d-block');
        }

    })
});
